import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Box, Typography , Button } from '@mui/material';
import { Link } from 'react-router-dom';
import UsersImage from '../../public/user.jpg';

function SidebarContainer() {

        // Function to remove the token cookie
        const handleLogout = () => {
            // Sets the token cookie to expire in the past, effectively removing it
            document.cookie = "token=; max-age=0; path=/; samesite=None";
            // Add any other logout logic here (e.g., redirecting the user or updating the state)
            // window.location.href = "http://localhost:3002/";
            window.location.href = "https://godatafactory.com/";
        };
    return (
        <Sidebar>
            <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            height="100%"
            >
                <Menu>
                    <SubMenu label="Indicateurs">
                        <SubMenu label="Données financières">
                            <MenuItem  component={<Link to="/" />}> Résumé </MenuItem> {/* Ajouter un lien approprié si nécessaire */}
                            <MenuItem component={<Link to="/details" />}> Détails </MenuItem>
                        </SubMenu>
                        <MenuItem component={<Link to="/dashboards" />}> Tableau de bord </MenuItem>
                    </SubMenu>               
                    <MenuItem component={<Link to="/help" />}> Aide </MenuItem>
                </Menu>

                <Box mb="25px"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 'auto',
                    padding: '10px',
                    borderRadius: '10px',
                   
                }}  
                
                >
<Button
    onClick={handleLogout}
    variant="text"
    style={{
        color: 'red', // Define the color property
        margin: '1rem', // Set the margin shorthand property
        width: 'calc(100% - 24px)', // Full width minus padding
    }}
>
                    Se Déconnecter
                </Button>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <img src={UsersImage} alt="User" className='profile_picture'/>
                    </Box>
                   <Box textAlign="center">
                        <Typography
                            variant="h1"
                            fontWeight="bold"
                            sx={{
                                m: "10px 0 0 0",
                                fontSize: { xs: '1rem', sm: '2rem', md: '2rem', lg: '2rem', xl: '2rem' },                   
                              }}
                        >
                            TourInvest
                        </Typography>
                        <Typography variant="h5"
                        sx={{
                            m: "10px 0 0 0",
                            fontSize: { xs: '1rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' },
                          }}
                          >
                            Tshinsele Beston
                        </Typography>
                    </Box> 
                </Box>
            </Box>
        </Sidebar>
    )
}

export default SidebarContainer;
