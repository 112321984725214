import Topbar from "./scenes/global/Topbar";
import { Router, Route, Routes } from 'react-router-dom';
import Dashboard from "./scenes/dashboard/Dashboard";
import SidebarContainer from "./scenes/global/Sidebar";
import Financials from "./scenes/dashboard/Financials.jsx";
import Summary from "./scenes/dashboard/Summary.jsx";
import Help from "./scenes/Informations/Help.jsx";
import PrivateRoutes from "./scenes/global/PrivateRoutes.jsx";



function App() {
  return (
    <div className="app">
      <SidebarContainer />
      <main className="content">
      <Topbar />
      <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/dashboards" element={<Dashboard/>} />
        <Route path="/details" element={<Financials/>} />  
        <Route path="/" element={<Summary/>} /> 
        <Route path="/help" element={<Help/>} /> 
        </Route>      
        
      </Routes>
      </main>
    </div>
  );
}

export default App;
