import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MobileNotAllowed from '../global/MobileNotAllowed';
import axios from 'axios'; // or use fetch

//Composant pour l'onglet "Résumé"

function Summary() {

    // États pour stocker les données financières et la sélection de dates
    const [financialData, setFinancialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: new Date(2022, 0, 1), // Date de début par défaut
        endDate: new Date(), // Date de fin par défaut
    });

    // États pour stocker les résultats calculés
    const [revenue, setRevenue] = useState(0);
    const [totalCharges, setTotalCharges] = useState(0);
    const [netResult, setNetResult] = useState(0);

    // Fonction pour filtrer les données selon la plage de dates
    const filterDataByDate = (data, startDate, endDate) => {
        return data.filter((item) => {
            const itemDate = new Date(item.year, parseInt(item.month.split('_')[1], 10) - 1);
            return itemDate >= startDate && itemDate <= endDate;
        });
    };

 // Fonction pour récupérer les données de l'API
const fetchFinancialData = async () => {
    setLoading(true);
    try {
        const response = await axios.post('https://api.43213598.godatafactory.com/api/charges/', {
            type: [
                'Achats',
                'transports',
                'services_exterieurs',
                'immpot_taxes',
                'autres_charges',
                'charge_personnel',
                'charges_frais_financiers',
                'dotations_amortissements',
                'ventes',
            ],
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Check if the request was successful
        if (response.status === 200) {
            const data = response.data;
            setFinancialData(data);
            console.log('Données récupérées:', data);
        } else {
            throw new Error('Erreur lors de la récupération des données');
        }
    } catch (error) {
        console.error('Erreur:', error);
    } finally {
        setLoading(false);
    }
};

    // Fonction pour calculer le chiffre d'affaires
    const calculateRevenue = (data) => {
        return data
            .filter((item) => item.charge_category === 'ventes')
            .reduce((acc, item) => acc + item.amount, 0);
    };

    // Fonction pour calculer la somme des charges
    const calculateTotalCharges = (data) => {
        return data
            .filter((item) =>
                [
                    'Achats',
                    'transports',
                    'services_exterieurs',
                    'immpot_taxes',
                    'autres_charges',
                    'charge_personnel',
                    'charges_frais_financiers',
                    'dotations_amortissements',
                ].includes(item.charge_category),
            )
            .reduce((acc, item) => acc + item.amount, 0);
    };

    // Fonction pour calculer tous les indicateurs
    const calculateSums = () => {
        const filteredData = filterDataByDate(financialData, dateRange.startDate, dateRange.endDate);

        // Calcul des différents indicateurs
        const revenue = calculateRevenue(filteredData);
        const charges = calculateTotalCharges(filteredData);
        const netResult = revenue - charges;

        // Mise à jour des états avec les valeurs calculées
        setRevenue(revenue);
        setTotalCharges(charges);
        setNetResult(netResult);
    };

    // Utilisez useEffect pour récupérer les données au montage
    useEffect(() => {
        fetchFinancialData();
    }, []); // Ajoutez des dépendances si nécessaire



    //GRAPHIQUE EN LIGNE 
    const [chartData, setChartData] = useState([]); // État pour les données du graphique

    // Fonction pour transformer les données financières pour le graphique
    const prepareChartData = (data) => {
        const monthlyData = {};

        data.forEach((item) => {
            const monthYearKey = `${item.month}_${item.year}`;
            if (!monthlyData[monthYearKey]) {
                monthlyData[monthYearKey] = {
                    month: item.month,
                    year: item.year,
                    revenue: 0,
                    charges: 0,
                    netResult: 0,
                };
            }

            if (item.charge_category === 'ventes') {
                monthlyData[monthYearKey].revenue += item.amount;
            } else {
                monthlyData[monthYearKey].charges += item.amount;
            }
            monthlyData[monthYearKey].netResult = monthlyData[monthYearKey].revenue - monthlyData[monthYearKey].charges;
        });

        // Convertir les données en tableau trié par mois et année
        const sortedMonthlyData = Object.values(monthlyData).sort((a, b) =>
            new Date(a.year, parseInt(a.month.split('_')[1]) - 1) - new Date(b.year, parseInt(b.month.split('_')[1]) - 1)
        );

        setChartData(sortedMonthlyData);
    };

    useEffect(() => {
        if (financialData.length > 0) {
            calculateSums();
            prepareChartData(financialData);
            console.log('Données du graphique:', chartData);
        }
    }, [financialData, dateRange]);





    return (
        <div display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
            {loading ? (
                <div>Chargement...</div>
            ) : (
               
                <Grid margin={2} container spacing={2} display={{ xs: 'none', sm: 'flex' }} alignItems={'center'} justifyContent={'center'}>
                     <MobileNotAllowed />
                    {/* Chiffre d'Affaires */}
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Chiffre d'Affaires
                                    <br />
                                    <TrendingUpIcon style={{ color: '#1976d2' }} />
                                </Typography>
                                <Typography variant="h5">
                                    {revenue.toFixed(2)} €
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Total des Charges */}
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Total des Charges
                                    <br />
                                    <ReceiptIcon style={{ color: '#1976d2' }} />
                                </Typography>
                                <Typography variant="h5">
                                    {totalCharges.toFixed(2)} €
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Résultat Net */}
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom >
                                    Résultat Net
                                    <br />
                                    <AccountBalanceWalletIcon style={{ color: '#1976d2' }} />
                                </Typography>
                                <Typography variant="h5">
                                    {netResult.toFixed(2)} €
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                        <Box sx={{ mt:6, p: 2, border: '1px solid #eee', borderRadius: '8px', textAlign: 'center' }}>
                            <Typography variant="h6">Évolution par Mois du CA, des Charges et du Résultat Net</Typography>
                            <Box sx={{ height: 400, maxWidth: '100%', mx: 'auto', overflow: 'hidden' }}>
                                <LineChart
                                
                                    series={[
                                        { curve: "linear", data: chartData.map((item) => item.revenue), label: 'Chiffre d\'Affaires', color: '#8884d8' },
                                        { curve: "linear", data: chartData.map((item) => item.charges), label: 'Total des Charges', color: '#82ca9d' },
                                        { curve: "linear", data: chartData.map((item) => item.netResult), label: 'Résultat Net', color: '#ffc658' },
                                    ]}
                                    xAxis={[{ scaleType: 'point', data: chartData.map((item) => `${item.month}-${item.year}`) }]}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default Summary;
